<template>
  <div class="time-axis-pane" ref="dom">
    <div
      :class="['prev', 'moveBtn', timeAxisIndex === 0 ? 'btn-disabled' : '']"
      @click="prev"
    ></div>
    <div
      :class="[
        'next',
        'moveBtn',
        timeAxisIndex === course.length - 1 ? 'btn-disabled' : '',
      ]"
      @click="next"
    ></div>
    <div class="time-container">
      <ul
        class="time-axis-wrapper"
        @mousedown="initDrag"
        :style="{ transform: `translate3d(${piexl}px, 0, 0)` }"
      >
        <li v-for="(item, index) in course" :key="index">
          <div
            :class="[
              'year-value',
              index === timeAxisIndex ? 'year-value-actived' : '',
            ]"
            @click="() => cut(index)"
          >
            <p>{{ item.name }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="content-container">
      <ul
        class="content-box"
        @mousedown="conInitDrag"
        :style="{
          transform: `translate3d(${contentPiexl}px, 0, 0)`,
          height: `${height}px`,
        }"
        ref="contentBox"
      >
        <li
          class="content-item"
          v-for="(item, index) in course"
          :key="item.name"
          v-html="item.news[0].content"
        ></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    course: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      timeAxisIndex: 0,
      piexl: 0,
      lastPiexl: 0,

      isDrag: false,
      oDragObj: "",

      nTX: 0,
      x: 0,

      contentPiexl: 0,
      lastContentPiexl: 0,
      conIsDrag: false,
      conODragObj: "",
      conNTX: 0,
      conX: 0,

      height: 0,

      totalWidth: 0,
    };
  },
  methods: {
    prev() {
      if (this.timeAxisIndex === 0) return;
      this.timeAxisIndex -= 1;
      this.contentPiexl = -this.timeAxisIndex * this.totalWidth;
      this.lastContentPiexl = this.contentPiexl;
      if (this.piexl > (-(this.timeAxisIndex - 2) * this.totalWidth) / 3) {
        this.piexl = (-(this.timeAxisIndex - 2) * this.totalWidth) / 3;
        this.lastPiexl = this.piexl;
      }
      if ((this.timeAxisIndex * this.totalWidth) / 3 + this.piexl > 0) return;
      this.piexl = -this.timeAxisIndex * this.totalWidth / 3;
      this.lastPiexl = this.piexl;
    },

    next() {
      // debugger
      if (this.timeAxisIndex === this.course.length - 1) return;
      this.timeAxisIndex += 1;
      this.contentPiexl = -this.timeAxisIndex * this.totalWidth;
      this.lastContentPiexl = this.contentPiexl;
      if (this.piexl < (-this.timeAxisIndex * this.totalWidth) / 3) {
        this.piexl = (-this.timeAxisIndex * this.totalWidth) / 3;
        this.lastPiexl = this.piexl;
      }
      if (
        ((this.timeAxisIndex + 1) * this.totalWidth) / 3 + this.piexl <
        this.totalWidth
      )
        return;
      this.piexl = (-(this.timeAxisIndex - 2) * this.totalWidth) / 3;
      this.lastPiexl = this.piexl;
    },

    cut(index) {
      if (this.timeAxisIndex == index) return;
      this.timeAxisIndex = index;
      this.contentPiexl = -this.timeAxisIndex * this.totalWidth;
      this.lastContentPiexl = this.contentPiexl;
    },

    initDrag(e) {
      let oDragHandle = e.target;
      let topElement = "HTML";
      while (
        oDragHandle.tagName != topElement &&
        oDragHandle.className != "time-axis-wrapper"
      ) {
        oDragHandle = oDragHandle.parentNode;
      }
      if (oDragHandle.className == "time-axis-wrapper") {
        this.isDrag = true;
        this.oDragObj = oDragHandle;
        this.nTX = parseInt(this.oDragObj.style.left + 0);
        this.x = e.clientX;
        document.onmousemove = this.dragMove;
        document.onmouseup = this.destroyDrag;
        return false;
      }
    },
    conInitDrag(e) {
      let oDragHandle = e.target;
      let topElement = "HTML";
      while (
        oDragHandle.tagName != topElement &&
        oDragHandle.className != "content-box"
      ) {
        oDragHandle = oDragHandle.parentNode;
      }
      if (oDragHandle.className == "content-box") {
        this.conIsDrag = true;
        this.conODragObj = oDragHandle;
        this.conNTX = parseInt(this.conODragObj.style.left + 0);
        this.conX = e.clientX;
        document.onmousemove = this.dragMove;
        document.onmouseup = this.destroyDrag;
        return false;
      }
    },

    dragMove(e) {
      if (this.isDrag) {
        this.piexl = this.nTX + e.clientX - this.x + this.lastPiexl;
        return false;
      }
      if (this.conIsDrag) {
        this.contentPiexl =
          this.conNTX + e.clientX - this.conX + this.lastContentPiexl;
        return false;
      }
    },

    destroyDrag() {
      if (this.isDrag) {
        this.isDrag = false;
        this.lastPiexl = this.piexl;
        if (this.piexl > 0) {
          this.piexl = 0;
          this.lastPiexl = 0;
        } else if (
          this.piexl <
          (-(
            this.course.length -
            Math.ceil(this.totalWidth / this.totalWidth / 3)
          ) *
            this.totalWidth) /
            3
        ) {
          this.piexl =
            (-(
              this.course.length -
              Math.ceil(this.totalWidth / this.totalWidth / 3)
            ) *
              this.totalWidth) /
            3;
          this.lastPiexl = this.piexl;
        }
      }
      if (this.conIsDrag) {
        this.conIsDrag = false;
        if (this.contentPiexl > 0) {
          this.contentPiexl = 0;
          this.lastContentPiexl = 0;
          return;
        } else if (
          this.contentPiexl <
          -(this.course.length - 1) * this.totalWidth
        ) {
          this.contentPiexl = -(this.course.length - 1) * this.totalWidth;
          this.lastContentPiexl = this.contentPiexl;
          return;
        }
        if (this.contentPiexl - this.lastContentPiexl >= 400) {
          this.prev();
        } else if (this.contentPiexl - this.lastContentPiexl <= -400) {
          this.next();
        } else {
          this.contentPiexl = this.lastContentPiexl;
        }
        this.lastContentPiexl = this.contentPiexl;
      }
    },
  },
  watch: {
    timeAxisIndex(n) {
      this.height = 0;
      // this.$refs.contentBox.children[n].children.forEach((item) => {
      //   this.height += item.clientHeight;
      // });
      for(let i in this.$refs.contentBox.children[n].children){
        let item = this.$refs.contentBox.children[n].children[i];
        if(item.clientHeight){
          this.height += item.clientHeight;
        }
      }
    },
    course(n) {
      this.$nextTick(() => {
        this.height = 0;
        // this.$refs.contentBox.children[this.timeAxisIndex].children.forEach(
        //   (item) => {
        //     this.height += item.clientHeight;
        //   }
        // );
        for(let i in this.$refs.contentBox.children[this.timeAxisIndex].children){
          let item = this.$refs.contentBox.children[this.timeAxisIndex].children[i];
          if(item.clientHeight){
            this.height += item.clientHeight;
          }
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.totalWidth = this.$refs.dom.clientWidth;
    });
  },
  destroyed() {
    document.onmousemove = null;
    document.onmouseup = null;
  },
};
</script>

<style lang="less" scoped>
.time-axis-pane {
  width: calc(100% - 2rem);
  margin: 0 auto;
  margin-top: 40px;
  position: relative;
  background: url(/imgs/line01.jpg) top 30px center no-repeat;
  .time-container {
    height: 60px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
    ul {
      transition: all 0.5s ease-out;
      display: flex;
      li {
        cursor: pointer;
        height: 60px;
        width: calc(100% / 3);
        flex-shrink: 0;
        // margin-right: 10px;
        .year-value {
          text-align: center;
          width: 62px;
          height: 60px;
          border-radius: 50%;
          background: #f2f2f2;
          padding-top: 10px;
          line-height: 20px;
          margin: 0 auto;
          p {
            line-height: 40px;
          }
        }
        .year-value-actived {
          color: #fff;
          background: #ff9c00;
        }
      }
    }
  }
  .content-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 40px auto 0;
    height: auto;
    .content-box {
      display: flex;
      transition: all 0.5s ease-out;
      .content-item {
        flex-shrink: 0;
        width: 100%;
      }
    }
  }
  .moveBtn {
    position: absolute;
    top: 18px;
    width: 14px;
    height: 24px;
    cursor: pointer;
    background-size: 14px 24px !important;
  }
  .btn-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  .prev {
    left: -0.5rem;
    background: url("/imgs/d-left01.png") left top no-repeat;
  }
  .next {
    right: -0.5rem;
    background: url("/imgs/d-right01.png") left top no-repeat;
  }
}
</style>